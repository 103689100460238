/* istanbul ignore file */
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enTranslation from './i18n/en.json';
import itTranslation from './i18n/it.json';


i18n
  .use(detector)
  .use(initReactI18next)
  .init({

    resources: {
      it: {
        translation: itTranslation,
      },
      en: {
        translation: enTranslation,
      },
    },
    supportedLngs: ["en", "it"],
    fallbackLng: ["en", "it"],
    load: 'all',
    lng: localStorage.getItem("lang") || "en",


    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },

    react: {
      
      useSuspense: true,
    },
  });

export default i18n;
