import React, { useContext } from "react";

import {
	useGetSkillsQuery,
	useGetTechnologiesQuery,
	useGetTitlesQuery,
} from "api/apiService";
import i18n from "i18n";

import SettingsContext from "../../store/settings-context";
import BubbleItem from "../UI/BubbleItem";
import Card from "../UI/Card";
import InfoBox, { InfoBoxItem } from "../UI/InfoBox";

const Skills = () => {
	const settingsContext = useContext(SettingsContext);

	const {
		data: skillsData,
		isLoading: isLoadingSkillsData,
		isFetching: isFetchingSkillsData,
		isError: isErrorSkillsData,
	} = useGetSkillsQuery();

	const {
		data: technologiesData,
		isLoading: isLoadingTechnologiesData,
		isFetching: isFetchingTechnologiesData,
		isError: isErrorTechnologiesData,
	} = useGetTechnologiesQuery();

	const {
		data: titlesData,
		isLoading: isLoadingTitlesData,
		isFetching: isFetchingTitlesData,
		isError: isErrorTitlesData,
	} = useGetTitlesQuery(settingsContext.lang);

	const contentSkills = skillsData?.map((item) => {
		return <li key={item.title}>{item.title}</li>;
	});

	const dataInfo: InfoBoxItem[] = [
		{
			id: "i3",
			title: i18n.t("skills.title"),
			content: <ul className="standard-list">{contentSkills}</ul>,
		},
		{
			id: "i4",
			title: i18n.t("skills.technologies"),
			content: <BubbleItem data={technologiesData} />,
		},
	];

	return (
		<Card
			id="Skills"
			isLoading={
				isLoadingTechnologiesData ||
				isFetchingTechnologiesData ||
				isLoadingSkillsData ||
				isFetchingSkillsData ||
				isLoadingTitlesData ||
				isFetchingTitlesData
			}
			isError={
				isErrorTitlesData || isErrorTechnologiesData || isErrorSkillsData
			}
		>
			<h3 className="section-title">{i18n.t("skills.title").toUpperCase()}</h3>
			<p className="section-description">{titlesData?.skillsText}</p>
			<hr className="divider" />
			<InfoBox data={dataInfo} />
		</Card>
	);
};

export default Skills;
