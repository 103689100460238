export const executeScroll = (el: string, callback?: () => void) => {
  const element = document.getElementById(el);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    element.focus({ preventScroll: true });
    callback?.();
  }
};
