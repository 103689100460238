import { configureStore } from "@reduxjs/toolkit";
import { siteDataApi } from "api/apiService";

export const store = configureStore({
	reducer: {
		[siteDataApi.reducerPath]: siteDataApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(siteDataApi.middleware),
});
