import { APIKEY, APPID } from "./const";
export const firebaseConfig = {
  apiKey: APIKEY,
  databaseURL: "https://enricococciasite-default-rtdb.firebaseio.com/",
  authDomain: "enricococciasite.firebaseapp.com",
  projectId: "enricococciasite",
  storageBucket: "enricococciasite.appspot.com",
  messagingSenderId: "436370488326",
  appId: APPID,
  measurementId: "G-SWNZYPD7DD",
};
