import React, { FC, useReducer } from "react";
import { ReactNode } from "react";

import SettingsContext from "./settings-context";

export type SettingStateType = {
  lang: string;
  mode: string;
};

export type SettingContextType = SettingStateType & {
  selectLang: (lang?: string) => void | {};
  selectMode: (mode?: string) => void | {};
};

const defaultSettingsState = {
  lang: localStorage.getItem("lang") || "en",
  mode:
    localStorage.getItem("mode") !== null
      ? localStorage.getItem("mode")
      : window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light",
};

const settingsReducer = (state: SettingStateType, action: any) => {
  if (action.type === "SELECT_LANG") {
    localStorage.setItem("lang", action.lang);
    return {
      lang: action.lang,
      mode: state.mode,
    };
  }
  if (action.type === "SELECT_MODE") {
    localStorage.setItem("mode", action.mode);
    return {
      lang: state.lang,
      mode: action.mode,
    };
  }

  return defaultSettingsState;
};

type SettingsProviderProps = {
  children?: ReactNode;
};

const SettingsProvider: FC<SettingsProviderProps> = ({ children }) => {
  const [settingsState, dispatchSettingsAction] = useReducer(
    settingsReducer,
    defaultSettingsState
  );

  const selectLangHandler = (lang?: string) => {
    dispatchSettingsAction({
      type: "SELECT_LANG",
      lang,
    });
  };

  const selectModeHandler = (mode?: string) => {
    dispatchSettingsAction({
      type: "SELECT_MODE",
      mode,
    });
  };

  const settingsContext: SettingContextType = {
    lang: settingsState.lang,
    mode: settingsState.mode,
    selectLang: selectLangHandler,
    selectMode: selectModeHandler,
  };

  return (
    <SettingsContext.Provider value={settingsContext}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
