import React, { FC, ReactNode } from "react";

import styles from "./Button.module.css";

type ButtonProps = {
  children?: ReactNode;
  className?: string;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  onClick?: () => void;
  onKeyPress?: () => void;
};

const Button: FC<ButtonProps> = ({
  className,
  type,
  onKeyPress,
  onClick,
  disabled,
  children,
}) => {
  return (
    <button
      className={`${styles.button} ${className}`}
      type={type || "button"}
      onClick={onClick}
      onKeyPress={onKeyPress}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
