import React, { useMemo, useState } from "react";

import i18n from "i18n";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";

import { capitalizeFirstLetter } from "helper/helper";

import { executeScroll } from "../../../util/util";
import Backdrop from "../../UI/Backdrop";
import classes from "./Menu.module.css";

const Menu = () => {
  const menuData = useMemo(
    () => [
      {
        key: "Home",
        title: capitalizeFirstLetter(i18n.t("home.title")),
      },
      { key: "About", title: capitalizeFirstLetter(i18n.t("about.title")) },
      {
        key: "Experiences",
        title: capitalizeFirstLetter(i18n.t("experiences.title")),
      },
      {
        key: "Education",
        title: capitalizeFirstLetter(i18n.t("education.title")),
      },
      { key: "Skills", title: capitalizeFirstLetter(i18n.t("skills.title")) },
      { key: "Clients", title: capitalizeFirstLetter(i18n.t("clients.title")) },
      { key: "Contact", title: capitalizeFirstLetter(i18n.t("contact.title")) },
    ],
    []
  );
  const [isMenuMobileOpened, setMenuMobileOpened] = useState(false);

  const toggleMenuHandler = () => {
    if (window.innerWidth < 992) {
      setMenuMobileOpened(!isMenuMobileOpened);
    } else {
      setMenuMobileOpened(false);
    }
  };

  const menuClasses = isMenuMobileOpened
    ? `${classes.menu} ${classes.open}`
    : classes.menu;
  const toggleMenuClasses = isMenuMobileOpened
    ? `${classes.toggleMenu} ${classes.open}`
    : classes.toggleMenu;

  return (
    <>
      <div className={toggleMenuClasses} onClick={toggleMenuHandler}>
        <GiHamburgerMenu className={classes.iconOpenMenu} />
      </div>

      {isMenuMobileOpened && (
        <Backdrop
          onClose={toggleMenuHandler}
          className={classes.backdropMobile}
        ></Backdrop>
      )}
      <nav>
        <ul className={menuClasses}>
          <li className={classes.closeMobile}>
            <span
              role="button"
              tabIndex={0}
              aria-label={i18n.t("menu.closeMenuMobile") || ""}
              onClick={toggleMenuHandler}
            >
              <GrClose className={classes.iconCloseMenu} />
            </span>
          </li>
          {menuData.map((item) => {
            return (
              <li key={item.key}>
                <span
                  onClick={() => executeScroll(item.key, toggleMenuHandler)}
                  onKeyPress={() => executeScroll(item.key, toggleMenuHandler)}
                  role="link"
                  tabIndex={0}
                >
                  {item.title}
                </span>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default Menu;
