import React, { FC } from "react";

import i18n from "i18n";

type NoDataProps = {
	customText?: string;
};

const NoData: FC<NoDataProps> = ({ customText }) => {
	return <p>{customText ?? i18n.t('notFound')}</p>;
};

export default NoData;
