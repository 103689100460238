import React from "react";

import i18n from "i18n";
import { RiArrowUpSLine } from "react-icons/ri";

import { BackgroundShapes } from "../../../assets/svg/BackgroundShapes";
import useScroll from "../../../hooks/useScroll";
import { executeScroll } from "../../../util/util";
import classes from "./Footer.module.css";

const Footer = () => {
  const isScrolledObg = useScroll();
  const isScrolled = isScrolledObg.scrollToTop;
  const scrollToTopClass = isScrolled
    ? `${classes.scrollToTop} ${classes.visible}`
    : classes.scrollToTop;

  return (
    <>
      <footer className={classes.footer}>
        <p>
          Enrico Coccia Resume @ All Rights Reserved {new Date().getFullYear()}
        </p>
      </footer>
      <div
        onClick={() => executeScroll("Home")}
        onKeyPress={() => executeScroll("Home")}
        role="link"
        tabIndex={0}
        aria-label={i18n.t("footer.scrollToTop") || ""}
        className={scrollToTopClass}
      >
        <RiArrowUpSLine />
      </div>
      <BackgroundShapes />
    </>
  );
};

export default Footer;
