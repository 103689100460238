import React, { FC } from "react";

import { PersonalInfo } from "api/types";

import NoData from "./NoData";
import classes from "./SimpleTable.module.css";

type SimpleTableProps = {
	data?: PersonalInfo[];
};

const SimpleTable: FC<SimpleTableProps> = ({ data }) => {
	if (data && data.length > 0) {
		const dataTable = data.map((item) => {
			let value = <p className={classes.value}>{item.value}</p>;
			if (item.url) {
				let mailTo = item.url.includes("@") ? "mailto:" : "";
				value = (
					<p className={classes.value}>
						<a href={`${mailTo}${item.url}`}>{item.value}</a>
					</p>
				);
			}
			return (
				<div key={item.key}>
					<p className={classes.label}>{item.label}</p>
					{value}
				</div>
			);
		});
		return <div className={classes.gridTable}>{dataTable}</div>;
	} else {
		return <NoData />;
	}
};

export default SimpleTable;
