import React, { FC, ReactNode } from "react";

import classes from "./Loading.module.css";

type LoadingProps = {
	children?: ReactNode;
};

const Loading: FC<LoadingProps> = ({ children }) => {
	return <div className={classes.loaderWrapper}><div className={classes.loader}>{children}</div></div>;
};

export default Loading;
