import React, { FC } from "react";

import { Language } from "api/types";
import i18n from "i18n";

import NoData from "./NoData";
import classes from "./Progress.module.css";

type ProgressProps = {
  data?: Language[];
  maxValue: number;
};

const Progress: FC<ProgressProps> = ({ data, maxValue }) => {
  if (data && data.length > 0) {
    const dataProgress = data.map((item) => {
      let bulletProgress: { classes: string; key: number }[] = [];

      for (let i = 1; i <= maxValue; i++) {
        bulletProgress.push({
          classes:
            i <= item.value
              ? `${classes.bullet} ${classes.fill}`
              : classes.bullet,
          key: i,
        });
      }
      const bulletContent = bulletProgress.map((item) => {
        return (
          <span className={item.classes} key={item.key}>
            &nbsp;
          </span>
        );
      });

      return (
        <div
          className={classes.progress}
          role="progressbar"
          key={item.title}
          aria-valuemin={0}
          aria-valuenow={item.value}
          aria-valuemax={maxValue}
          aria-label={`${i18n.t("progress.language")} ${item.title}`}
        >
          <p className={classes.title}>{item.title}</p>
          <div className={classes.progressBar}>{bulletContent}</div>
          <p className={classes.description}>{item.description}</p>
        </div>
      );
    });
    return <div>{dataProgress}</div>;
  } else {
    return <NoData />;
  }
};

export default Progress;
