import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import {
	createApi,
	fetchBaseQuery,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";
import { firebaseConfig } from "firebase-config";
import { getAuth, signInAnonymously } from "firebase/auth";

import { retrieveStoredToken } from "helper/helper";

import type {
	Client,
	Experience,
	Language,
	PersonalInfo,
	Privacy,
	Skill,
	Technology,
	Titles,
} from "./types";

const asyncFn = async <T>(
	url: string,
	fetchWithBQ: (
		url: string
	) => MaybePromise<
		QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
	>
) => {
  let tokenDuration = retrieveStoredToken()?.duration;
	if (tokenDuration <= 3600) {
		localStorage.removeItem("anonymous_user_token");
		localStorage.removeItem("expirationTime");
	}
	let tokenRes = retrieveStoredToken()?.token;
	if (!tokenRes) {
		const auth = await getAuth();
		tokenRes = await signInAnonymously(auth)
			.then(async (res) => {
        const user = await res.user.getIdTokenResult();
				const token = user.token;
        const expirationTime = user.expirationTime;
        localStorage.setItem("anonymous_user_token", token);
        localStorage.setItem("expirationTime", expirationTime);
				return token || "";
			})
			.catch(() => {
				return "";
			});
		if (!tokenRes) return { error: { status: 401 } as FetchBaseQueryError };
	}
	const result = await fetchWithBQ(`${url}?auth=${tokenRes}`);
	if (result.error) return { error: result.error as FetchBaseQueryError };
	return { data: result.data as T };
};

export const siteDataApi = createApi({
	reducerPath: "siteData",
	refetchOnMountOrArgChange: true,
	baseQuery: fetchBaseQuery({ baseUrl: firebaseConfig.databaseURL }),
	endpoints: (builder) => ({
		getClients: builder.query<Client[], void>({
			async queryFn(_lang, _queryApi, _extraOptions, fetchWithBQ) {
				return await asyncFn<Client[]>(`clients.json`, fetchWithBQ);
			},
		}),
		getSkills: builder.query<Skill[], void>({
			async queryFn(_lang, _queryApi, _extraOptions, fetchWithBQ) {
				return await asyncFn<Skill[]>(`skills.json`, fetchWithBQ);
			},
		}),
		getTechnologies: builder.query<Technology[], void>({
			async queryFn(_lang, _queryApi, _extraOptions, fetchWithBQ) {
				return await asyncFn<Technology[]>(
					`technologies.json`,
					fetchWithBQ
				);
			},
		}),
		getPrivacy: builder.query<Privacy, string>({
			async queryFn(lang, _queryApi, _extraOptions, fetchWithBQ) {
				return await asyncFn<Privacy>(`privacy/${lang}.json`, fetchWithBQ);
			},
		}),
		getTitles: builder.query<Titles, string>({
			async queryFn(lang, _queryApi, _extraOptions, fetchWithBQ) {
				return await asyncFn<Titles>(`titles/${lang}.json`, fetchWithBQ);
			},
		}),
		getPersonalInfo: builder.query<PersonalInfo[], string>({
			async queryFn(lang, _queryApi, _extraOptions, fetchWithBQ) {
				return await asyncFn<PersonalInfo[]>(
					`personal/${lang}.json`,
					fetchWithBQ
				);
			},
		}),
		getLanguages: builder.query<Language[], string>({
			async queryFn(lang, _queryApi, _extraOptions, fetchWithBQ) {
				return await asyncFn<Language[]>(`languages/${lang}.json`, fetchWithBQ);
			},
		}),
		getExperiences: builder.query<Experience[], string>({
			async queryFn(lang, _queryApi, _extraOptions, fetchWithBQ) {
				return await asyncFn<Experience[]>(
					`experiences/${lang}.json`,
					fetchWithBQ
				);
			},
		}),
		getEducation: builder.query<Experience[], string>({
			async queryFn(lang, _queryApi, _extraOptions, fetchWithBQ) {
				return await asyncFn<Experience[]>(
					`education/${lang}.json`,
					fetchWithBQ
				);
			},
		}),
	}),
});

export const {
	useGetClientsQuery,
	useGetSkillsQuery,
	useGetTechnologiesQuery,
	useGetPrivacyQuery,
	useGetTitlesQuery,
	useGetPersonalInfoQuery,
	useGetLanguagesQuery,
	useGetExperiencesQuery,
	useGetEducationQuery,
} = siteDataApi;
