import React, { FC, ReactNode } from "react";

import i18n from "i18n";
import ScrollAnimation from "react-animate-on-scroll";
import { RiArrowUpSLine } from "react-icons/ri";

import { executeScroll } from "../../util/util";
import Button from "./Button";
import classes from "./Card.module.css";
import ErrorWrap from "./ErrorWrap";
import Loading from "./Loading";

type CardProps = {
	children?: ReactNode;
	id: string;
	className?: string;
	isLoading?: boolean;
	isError?: boolean;
};

const Card: FC<CardProps> = ({
	id,
	className,
	children,
	isLoading,
	isError,
}) => {
	return (
		<section id={id} tabIndex={-1}>
			<ScrollAnimation
				animateIn="fadeInUp"
				duration={0.5}
				delay={0}
				animateOnce
			>
				<div className={`${classes.card} ${className ? className : ""}`}>
					{id !== "About" ? (
						<ScrollAnimation
							animateIn="bounceInRight"
							initiallyVisible
							animateOnce
						>
							<Button
								onClick={() => executeScroll("Home")}
								onKeyPress={() => executeScroll("Home")}
								aria-label={i18n.t("card.backToMenu") || "scroll to top"}
								className={classes.scrollToTopCard}
							>
								<RiArrowUpSLine />
							</Button>
						</ScrollAnimation>
					) : null}
					<div className={classes.sectionCard}>
						{!isLoading ? (
							<>
								{isError && <ErrorWrap />}
								<>{children}</>
							</>
						) : (
							<Loading />
						)}
					</div>
				</div>
			</ScrollAnimation>
		</section>
	);
};

export default Card;
