import React, { FC, ReactNode } from "react";

import ReactDOM from "react-dom";

import classes from "./Backdrop.module.css";

const portalElement =
  document.getElementById("overlay-root") || new DocumentFragment();

type BackdropProps = {
  children?: ReactNode;
  className?: string;
  onClose: () => void;
};

const Backdrop: FC<BackdropProps> = ({ className, onClose, children }) => {
  return (
    <>
      {ReactDOM.createPortal(
        <div className={`${classes.backdrop} ${className}`} onClick={onClose}>
          {children}
        </div>,
        portalElement
      )}
    </>
  );
};

export default Backdrop;
