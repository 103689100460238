import React, { FC, ReactNode } from "react";

import classes from "./InfoBox.module.css";
import NoData from "./NoData";

type InfoBoxProps = {
	data: InfoBoxItem[];
};

export type InfoBoxItem = { id: string; title: string; content: string | ReactNode };

const InfoBox: FC<InfoBoxProps> = ({ data }) => {
	if (data.length > 0) {
		const infoContent = data.map((item) => {
			return (
				<div className={classes.infoBox} key={`ib-${item.id}`}>
					<p className={classes.title}>{item.title}</p>
					{item.content}
				</div>
			);
		});
		return <div className={classes.infoBoxContainer}>{infoContent}</div>;
	} else {
		return <NoData />;
	}
};

export default InfoBox;
