import React from "react";

import { Provider } from "react-redux";

import { store } from "store";

import About from "./components/About/About";
import Clients from "./components/Clients/Clients";
import Contact from "./components/Contact/Contact";
import Education from "./components/Education/Education";
import Experiences from "./components/Experiences/Experiences";
import Footer from "./components/Layout/Footer/Footer";
import Menu from "./components/Layout/Menu/Menu";
import Wrapper from "./components/Layout/Wrapper/Wrapper";
import Skills from "./components/Skills/Skills";
import UserWidget from "./components/User/UserWidget";
import SettingsProvider from "./store/settings-provider";

const App = () => {
	return (
		<Provider store={store}>
			<SettingsProvider>
				<div className="container" id="Home" tabIndex={-1}>
					<Menu />
					<Wrapper>
						<UserWidget />
						<About />
						<Experiences />
						<Education />
						<Skills />
						<Clients />
						<Contact />
					</Wrapper>
					<Footer />
				</div>
			</SettingsProvider>
		</Provider>
	);
};

export default App;
