import React, { FC, ReactNode } from "react";

import Backdrop from "./Backdrop";
import classes from "./Modal.module.css";

type ModalProps = {
	children?: ReactNode;
	onClose: () => void;
};

const Modal: FC<ModalProps> = ({ children, onClose }) => {
	return (
		<div>
			<Backdrop onClose={onClose} />
			<div>
				<div className={classes.modal}>
					<div className={classes.content}>
						<div
							className={classes.closeButton}
							onClick={onClose}
							role="button"
							tabIndex={0}
						>
							X
						</div>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;
