import React, { FC, ReactNode } from "react";

import i18n from "i18n";

import classes from "./ErrorWrap.module.css";

type ErrorWrapProps = {
	children?: ReactNode;
};

const ErrorWrap: FC<ErrorWrapProps> = ({ children }) => {
	return <div className={classes.errorWrapper}>{children ?? i18n.t('error')}</div>;
};

export default ErrorWrap;
