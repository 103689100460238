import React from "react";
import { useContext, useEffect } from "react";

import i18n from "i18n";
import { FaLinkedinIn, FaGithubAlt, FaDownload } from "react-icons/fa";

import profileImg from "../../assets/images/profile.webp";
import useScroll from "../../hooks/useScroll";
import SettingsContext from "../../store/settings-context";
import { SettingContextType } from "../../store/settings-provider";
import Button from "../UI/Button";
import classes from "./UserWidget.module.css";

const UserWidget = () => {
  const isScrolledObg = useScroll();
  const isScrolled = isScrolledObg.scroll;
  let widgetClass = classes.widget;
  if (isScrolled) {
    widgetClass = `${classes.widget} ${classes.sticky}`;
  }

  const settingsContext: SettingContextType = useContext(SettingsContext);
  const changeLanguageHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    settingsContext.selectLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const changeModeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    settingsContext.selectMode(event.target.value);
  };

  useEffect(() => {
    document.body.className = "";
    document.body.classList.add(settingsContext.mode);
  }, [settingsContext.mode]);

  return (
    <div className={classes.widgetContainer}>
      <div className={widgetClass}>
        <div className={classes.widgetInfo}>
          <img
            src={profileImg}
            className={classes.avatar}
            alt="Enrico Coccia"
            width="390"
            height="390"
          />
          <h4 className={classes.name}>Enrico Coccia</h4>
          <h2 className={classes.businessName}>Front End Developer</h2>
        </div>
        <div className={classes.widgetActions}>
          <div className={classes.widgetSelect}>
            {
              <select
                id="select-mode"
                className={classes.modeSelect}
                aria-label={i18n.t("userWidget.selectMode") || ""}
                onChange={changeModeHandler}
                defaultValue={settingsContext.mode}
              >
                <option value="light">Light</option>
                <option value="dark">Dark</option>
              </select>
            }
            {
              <select
                id="select-language"
                className={classes.langSelect}
                aria-label={i18n.t("userWidget.selectLanguage") || ""}
                onChange={changeLanguageHandler}
                defaultValue={settingsContext.lang}
              >
                <option value="en">{i18n.t("userWidget.english")}</option>
                <option value="it">{i18n.t("userWidget.italian")}</option>
              </select>
            }
          </div>
          <a
            href="https://github.com/enricococcia"
            rel="noreferrer"
            target="_blank"
            title={i18n.t("userWidget.githubProfile") || ""}
          >
            <FaGithubAlt />
          </a>
          <a
            href="https://www.linkedin.com/in/enrico-coccia-a09615172/"
            rel="noreferrer"
            target="_blank"
            title={i18n.t("userWidget.linkedinProfile") || ""}
          >
            <FaLinkedinIn />
          </a>
          <a
            href="https://github.com/enricococcia/mysite"
            rel="noreferrer"
            target="_blank"
            title={i18n.t("userWidget.siteCode") || ""}
          >
            <FaDownload />
          </a>
          <div>
            <Button
              onClick={() =>
                window.open("https://www.enricococcia.com/resume.zip", "_blank")
              }
            >
              {i18n.t("userWidget.downloadResume")}
            
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWidget;
