import React, { FC } from "react";

import i18n from "i18n";

import { validateEmail } from "../../helper/helper";
import useInput from "../../hooks/useInput";
import Button from "../UI/Button";
import classes from "./ContactForm.module.css";

export type ContactFormDataType = {
  name: string;
  email: string;
  message: string;
};

type ContactFormProps = {
  loading?: boolean;
  error?: { message: string } | null;
  onSend: (data: ContactFormDataType) => void;
  openModal: (open: boolean) => void;
};

const ContactForm: FC<ContactFormProps> = ({
  loading,
  error,
  onSend,
  openModal,
}) => {

  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.trim() !== "");

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput((value) => validateEmail(value));

  const {
    value: enteredMessage,
    isValid: enteredMessageIsValid,
    hasError: messageInputHasError,
    valueChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    reset: resetMessage,
  } = useInput((value) => value.trim() !== "");

  const submitHandler = (event: React.SyntheticEvent) => {
    event?.preventDefault();
    if (!enteredNameIsValid || !enteredEmailIsValid || !enteredMessage) {
      return;
    }

    const dataToSave = {
      name: enteredName,
      email: enteredEmail,
      message: enteredMessage,
    };

    resetName();
    resetEmail();
    resetMessage();

    onSend(dataToSave);
  };

  let formIsValid = false;

  if (enteredNameIsValid && enteredEmailIsValid && enteredMessageIsValid) {
    formIsValid = true;
  }

  const validClass = classes.control;
  const invalidClass = `${classes.control} ${classes.invalid}`;

  const checkValidClass = (hasError?: boolean) => {
    return hasError ? invalidClass : validClass;
  };

  return (
    <>
      <form className={classes.form} onSubmit={submitHandler}>
        <div className={checkValidClass(nameInputHasError)}>
          <label htmlFor="name">{i18n.t("contactForm.name")}*</label>
          <input
            type="text"
            id="name"
            value={enteredName}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
          />
          {nameInputHasError && <p>{i18n.t("contactForm.errorName")}</p>}
        </div>
        <div className={checkValidClass(emailInputHasError)}>
          <label htmlFor="email">{i18n.t("contactForm.email")}*</label>
          <input
            type="email"
            id="email"
            value={enteredEmail}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
          />
          {emailInputHasError && <p>{i18n.t("contactForm.errorEmail")}</p>}
        </div>
        <div className={checkValidClass(messageInputHasError)}>
          <label htmlFor="message">{i18n.t("contactForm.message")}</label>
          <textarea
            id="message"
            value={enteredMessage}
            onChange={messageChangeHandler}
            onBlur={messageBlurHandler}
            rows={5}
          />
          {messageInputHasError && <p>{i18n.t("contactForm.errorMessage")} </p>}
        </div>
        <input type="checkbox" id="privacy" name="privacy" required />
        <label className={classes.privacyPolicy} htmlFor="privacy">
          {i18n.t("contactForm.privacyText")}
          <span role="button" tabIndex={0} onClick={() => openModal(true)}>
            {i18n.t("contactForm.privacyLink")}
          </span>
        </label>
        {error && <div className={classes.error}>{error.message}</div>}
        {loading && <div className={classes.loader}></div>}
        {!loading && (
          <div className={classes.actions}>
            <Button
              disabled={!formIsValid}
              className={classes.submit}
              type="submit"
            >
              {i18n.t("contactForm.send")}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

export default ContactForm;
