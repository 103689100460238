import React from "react";
import { useContext } from "react";

import {
	useGetLanguagesQuery,
	useGetPersonalInfoQuery,
	useGetTitlesQuery,
} from "api/apiService";
import i18n from "i18n";

import SettingsContext from "../../store/settings-context";
import Card from "../UI/Card";
import InfoBox from "../UI/InfoBox";
import Progress from "../UI/Progress";
import SimpleTable from "../UI/SimpleTable";

const About = () => {
	const settingsContext = useContext(SettingsContext);

	const {
		data: titlesData,
		isLoading: isLoadingTitlesData,
		isFetching: isFetchingTitlesData,
		isError: isErrorTitlesData,
	} = useGetTitlesQuery(settingsContext.lang);

	const {
		data: personalInfoData,
		isLoading: isLoadingPersonalInfo,
		isFetching: isFetchingPersonalInfo,
		isError: isErrorPersonalInfo,
	} = useGetPersonalInfoQuery(settingsContext.lang);

	const {
		data: languagesData,
		isLoading: isLoadingLanguagesData,
		isFetching: isFetchingLanguagesData,
		isError: isErrorLanguagesData,
	} = useGetLanguagesQuery(settingsContext.lang);

	const dataInfo = [
		{
			id: "i1",
			title: i18n.t("personalInformation"),
			content: <SimpleTable data={personalInfoData} />,
		},
		{
			id: "i2",
			title: i18n.t("languages"),
			content: <Progress data={languagesData} maxValue={10} />,
		},
	];

	return (
		<Card
			id="About"
			isLoading={
				isLoadingPersonalInfo ||
				isFetchingPersonalInfo ||
				isLoadingLanguagesData ||
				isFetchingLanguagesData ||
				isLoadingTitlesData ||
				isFetchingTitlesData
			}
			isError={isErrorPersonalInfo || isErrorLanguagesData || isErrorTitlesData}
		>
			<h3 className="section-title">{i18n.t("about.title").toUpperCase()}</h3>
			<p className="section-description">
				<strong>
					{titlesData?.presentationTitle}
					<br />
				</strong>
				{titlesData?.presentationText}
			</p>
			<hr className="divider" />
			<InfoBox data={dataInfo} />
		</Card>
	);
};

export default About;
