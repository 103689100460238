import React, { FC } from "react";

import { Experience } from "api/types";
import parse from "html-react-parser";
import i18n from "i18n";
import ScrollAnimation from "react-animate-on-scroll";

import BubbleItem from "./BubbleItem";
import classes from "./ExperienceItem.module.css";

type ExperienceItemProps = {
  data: Experience;
  initiallyVisible: boolean;
};

const ExperienceItem: FC<ExperienceItemProps> = ({
  data,
  initiallyVisible,
}) => {
  return (
    <ScrollAnimation
      animateIn="pulse"
      duration={0.5}
      delay={0.5}
      animateOnce
      initiallyVisible={initiallyVisible}
    >
      <div className={classes.experience}>
        <div className={classes.date}>
          <p>
            <span>
              {data.monthStart} <strong>{data.yearStart}</strong>
            </span>
            -
            {data.isFinished ? (
              <span>
                {data.monthFinish} <strong>{data.yearFinish}</strong>
              </span>
            ) : (
              <span>{i18n.t("experienceItem.currently")}</span>
            )}
          </p>
        </div>
        <div className={classes.experienceInfo}>
          <h3>{data.title}</h3>
          {data.logo && (
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/${data.logo}.webp`}
              width={data.logoWidth}
              height={data.logoHeight}
              alt={data.title}
            />
          )}
          <p className={classes.company}>
            {data.company} - {data.place}
          </p>
          <div className={classes.description}>
            <p>{data.descriptionTitle}</p>
            <div>{parse(data.descriptionText)}</div>
          </div>
          {data.url && (
            <a
              className={classes.url}
              href={data.url}
              target="_blank"
              rel="noreferrer"
            >
              {i18n.t("experienceItem.courseLink")}
            </a>
          )}
          {data?.skills?.length > 0 && <BubbleItem data={data.skills} />}
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default ExperienceItem;
