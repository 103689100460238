import { useState, useEffect } from "react";

const useScroll = () => {
	const [scroll, setScroll] = useState(false);
	const [scrollToTop, setScrollToTop] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
			setScrollToTop(window.scrollY > 550);
		});
	}, []);

	return { scroll, scrollToTop };
};

export default useScroll;
