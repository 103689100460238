import React, { FC } from "react";

import { Technology } from "api/types";

import classes from "./BubbleItem.module.css";
import NoData from "./NoData";

type BubbleItemProps = {
  data?: Technology[];
};

const BubbleItem: FC<BubbleItemProps> = ({ data }) => {
  if (data && data.length > 0) {
    const items = data.map((item, index) => {
      return (
        <span
          className={item.level === "basic" ? classes.basic : ""}
          key={`${item.title}-b${index}`}
        >
          {item.title}
          {item.level === "basic" && "*"}
        </span>
      );
    });
    return <div className={classes.bubblesContainer}>{items}</div>;
  } else {
    return <NoData />;
  }
};

export default BubbleItem;
