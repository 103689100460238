import { Context, createContext } from "react";

import { SettingContextType } from "./settings-provider";

const SettingsContext: Context<SettingContextType> = createContext({
	lang: "en",
	mode: "light",
	selectLang: () => {},
	selectMode: () => {},
});

export default SettingsContext;
