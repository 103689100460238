import React from "react";
import { useContext } from "react";

import { useGetClientsQuery, useGetTitlesQuery } from "api/apiService";
import i18n from "i18n";

import NoData from "components/UI/NoData";

import SettingsContext from "../../store/settings-context";
import Card from "../UI/Card";
import classes from "./Clients.module.css";

const Clients = () => {
	const settingsContext = useContext(SettingsContext);

	const {
		data: clientsData,
		isLoading: isLoadingClientsData,
		isFetching: isFetchingClientsData,
		isError: isErrorClientsData,
	} = useGetClientsQuery();

	const {
		data: titlesData,
		isLoading: isLoadingTitlesData,
		isFetching: isFetchingTitlesData,
		isError: isErrorTitlesData,
	} = useGetTitlesQuery(settingsContext.lang);

	if (clientsData && clientsData?.length > 0) {
		const contentClients = clientsData.map((item) => {
			return (
				<span key={item.title}>
					<img
						src={`${process.env.PUBLIC_URL}/assets/images/clients/${item.image}.webp`}
						width="211"
						height="84"
						alt={item.title}
					/>
				</span>
			);
		});
		return (
			<Card
				id="Clients"
				isLoading={
					isLoadingClientsData ||
					isFetchingClientsData ||
					isLoadingTitlesData ||
					isFetchingTitlesData
				}
				isError={isErrorTitlesData || isErrorClientsData}
			>
				<h3 className="section-title">
					{i18n.t("clients.title").toUpperCase()}
				</h3>
				<p className="section-description">{titlesData?.clientsText}</p>
				<div className={classes.clientsContainer}>{contentClients}</div>
			</Card>
		);
	} else {
		return <NoData />;
	}
};

export default Clients;
