import React from "react";
import { useState, useContext } from "react";

import { useGetPrivacyQuery, useGetTitlesQuery } from "api/apiService";
import i18n from "i18n";

import useHttp from "../../hooks/useHttp";
import SettingsContext from "../../store/settings-context";
import Card from "../UI/Card";
import Modal from "../UI/Modal";
import ContactForm, { ContactFormDataType } from "./ContactForm";

const Contact = () => {
	const settingsContext = useContext(SettingsContext);

	const { isLoading, error, sendRequest: sendMessageRequest } = useHttp();

	const [isPrivacyModalOpened, setPrivacyModalOpened] = useState(false);

	const {
		data: privacyData,
		isLoading: isLoadingPrivacyData,
		isFetching: isFetchingPrivacyData,
		isError: isErrorPrivacyData,
	} = useGetPrivacyQuery(settingsContext.lang);

	const {
		data: titlesData,
		isLoading: isLoadingTitlesData,
		isFetching: isFetchingTitlesData,
		isError: isErrorTitlesData,
	} = useGetTitlesQuery(settingsContext.lang);

	const sendMessageHandler = async (dataForm: ContactFormDataType) => {
		sendMessageRequest({
			url: "https://www.enricococcia.com/app/api/user/contact.php",
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: { text: dataForm },
		});
	};

	const toggleModalHandler = (open: boolean) => {
		setPrivacyModalOpened(open);
	};

	return (
		<Card
			id="Contact"
			isLoading={
				isLoadingPrivacyData ||
				isFetchingPrivacyData ||
				isLoadingTitlesData ||
				isFetchingTitlesData
			}
			isError={isErrorTitlesData || isErrorPrivacyData}
		>
			<h3 className="section-title">{i18n.t("contact.title").toUpperCase()}</h3>
			<p className="section-description">{titlesData?.contactText}</p>

			<ContactForm
				error={error}
				onSend={sendMessageHandler}
				loading={isLoading}
				openModal={toggleModalHandler}
			/>

			{isPrivacyModalOpened && (
				<Modal onClose={() => toggleModalHandler(false)}>
					<h3>{i18n.t("contact.privacy")}</h3>
					<p>{privacyData?.text}</p>
				</Modal>
			)}
		</Card>
	);
};

export default Contact;
