import React from "react";

import { firebaseConfig } from "firebase-config";
import { initializeApp } from "firebase/app";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

if (
	window.location.hostname !== "localhost" &&
	window.location.hostname !== "127.0.0.1"
) {
	if (navigator.serviceWorker) {
		navigator.serviceWorker
			.register("/sw.js")
			.then(function (registration) {
				console.log(
					"ServiceWorker registration successful with scope:",
					registration.scope
				);
			})
			.catch(function (error) {
				console.log("ServiceWorker registration failed:", error);
			});
	}
}
