export const BackgroundShapes = () => {
  return (
    <>
      <svg
        id="crt-bg-shape-1"
        className="shape-grey"
        height="519"
        width="758"
        role="presentation"
      >
        <polygon points="0,455,693,352,173,0,92,0,0,71"></polygon>
      </svg>
      <svg
        id="crt-bg-shape-2"
        className="shape-blue"
        height="536"
        width="633"
        role="presentation"
      >
        <polygon points="0,0,633,0,633,536"></polygon>
      </svg>
    </>
  );
};
