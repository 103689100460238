import React, { useContext } from "react";

import { useGetExperiencesQuery, useGetTitlesQuery } from "api/apiService";
import i18n from "i18n";

import SettingsContext from "../../store/settings-context";
import Card from "../UI/Card";
import ExperienceItem from "../UI/ExperienceItem";
import NoData from "../UI/NoData";

const Experiences = () => {
	const settingsContext = useContext(SettingsContext);

	const {
		data: titlesData,
		isLoading: isLoadingTitlesData,
		isFetching: isFetchingTitlesData,
		isError: isErrorTitlesData,
	} = useGetTitlesQuery(settingsContext.lang);

	const {
		data: experiencesData,
		isLoading: isLoadingExperiencesData,
		isFetching: isFetchingExperiencesData,
		isError: isErrorExperiencesData,
	} = useGetExperiencesQuery(settingsContext.lang);

	return (
		<Card
			id="Experiences"
			isLoading={
				isLoadingExperiencesData ||
				isFetchingExperiencesData ||
				isLoadingTitlesData ||
				isFetchingTitlesData
			}
			isError={isErrorTitlesData || isErrorExperiencesData}
		>
			<h3 className="section-title">
				{i18n.t("experiences.title").toUpperCase()}
			</h3>
			<p className="section-description">{titlesData?.experienceText}</p>
			{experiencesData?.map((item, index) => {
				return (
					<ExperienceItem
						data={item}
						key={item.key}
						initiallyVisible={index === 0}
					/>
				);
			}) || <NoData />}
		</Card>
	);
};

export default Experiences;
