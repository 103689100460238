import React from "react";
import { useContext } from "react";

import { useGetEducationQuery, useGetTitlesQuery } from "api/apiService";
import i18n from "i18n";

import NoData from "components/UI/NoData";

import SettingsContext from "../../store/settings-context";
import Card from "../UI/Card";
import ExperienceItem from "../UI/ExperienceItem";

const Education = () => {
	const settingsContext = useContext(SettingsContext);

	const {
		data: titlesData,
		isLoading: isLoadingTitlesData,
		isFetching: isFetchingTitlesData,
		isError: isErrorTitlesData,
	} = useGetTitlesQuery(settingsContext.lang);

	const {
		data: educationData,
		isLoading: isLoadingEducationData,
		isFetching: isFetchingEducationData,
		isError: isErrorEducationData,
	} = useGetEducationQuery(settingsContext.lang);

	return (
		<Card
			id="Education"
			isLoading={
				isLoadingEducationData ||
				isFetchingEducationData ||
				isLoadingTitlesData ||
				isFetchingTitlesData
			}
			isError={isErrorTitlesData || isErrorEducationData}
		>
			<h3 className="section-title">
				{i18n.t("education.title").toUpperCase()}
			</h3>
			<p className="section-description">{titlesData?.educationText}</p>
			{educationData?.map((item, index) => {
				return (
					<ExperienceItem
						data={item}
						key={item.key}
						initiallyVisible={index === 0}
					/>
				);
			}) || <NoData />}
		</Card>
	);
};

export default Education;
